import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 3;
`;

export const ContainerBottom = styled(Container)`
  bottom: 0;
  transform: rotate(180deg);
`;

export const ContainerTop = styled(Container)`
  top: 0;
`;

export const Copy = styled.p.attrs((props) => ({
  style: {
    marginLeft: props.marginLeft + "px",
  },
}))`
  font-family: "d-dinregular";
  font-family: "courier";
  font-size: 16px;
  letter-spacing: 3px;
  white-space: nowrap;
  overflow: hidden;
  background-color: #fff;
  padding: 5px 20px;
  margin-top: 0px;
`;
