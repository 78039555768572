import Section from "./../Section";
import Slider from "./../../Slider";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/R2_as_favorite_color_-_2019.jpg"),
    column: 7,
    top: 1,
    width: 5,
    speed: -2,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/R2_at_night_-_2019.jpg"),
    column: 0,
    top: 4,
    width: 3,
    speed: 4,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/R2_the_gemini_-_2019.jpg"),
    column: 2,
    top: 4,
    width: 2,
    speed: -4,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/R2_is_this_color_-_2019.jpg"),
    column: 6,
    top: 6,
    width: 3,
    speed: -2,
    isLarge: null,
  },
];

const R2 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={8} left={0}>
        <Slider delay={3500} data={data} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default R2;
