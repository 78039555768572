import React from "react";
import styled from "styled-components";

const Background = styled.div`
  background-color: #000;
  opacity: ${({ showModal }) => (showModal ? "0.5" : "0")};
  transition: opacity 0.2s;
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${({ showModal }) => (showModal ? "all" : "none")};
`;

const Modal = ({ images, setImages }) => {
  const closeModal = () => {
    const arr = images.map((obj) => {
      obj.isLarge = false;
      return obj;
    });

    setImages([...arr]);
  };

  return (
    <Background
      showModal={images?.some(({ isLarge }) => isLarge === true)}
      onClick={() => closeModal()}
    />
  );
};

export default Modal;
