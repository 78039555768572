import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/GhostWillow2.jpg"),
    column: 3,
    top: 3,
    width: 7,
    speed: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/GhostWillow1.jpg"),
    column: 1,
    top: 6,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/GhostWillow0.jpg"),
    column: 9,
    top: 2,
    width: 2,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const GhostWillow = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={900} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={7} left={0}>
        <Slider delay={4400} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={2} mt={-2} left={6} pointer="none">
        <Rellax speed={3} center>
          <MobileImage src={data[2].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default GhostWillow;
