import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/Hawthorne,2016,detail.jpg"),
    column: 1,
    top: 0,
    width: 4,
    speed: -1,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Hawthorne,2016,detail2.jpg"),
    column: 4,
    top: 2,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_901.jpg"),
    column: 0,
    top: 3,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Hawthorne_-_2016.jpg"),
    column: 8,
    top: 0,
    width: 4,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter(
  (img, i) => img.type !== PORTRAIT && i !== 1 && img
);

const Hawthorne = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={600} />
  ) : (
    <MobileSectionWrapper height={1.4}>
      <MobileSection width={6} left={1}>
        <Slider delay={2800} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={4} mt={-1} left={0} pointer="none">
        <Rellax speed={1} center>
          <MobileImage src={data[3].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
      <MobileSection width={4} mt={-3} left={4} pointer="all">
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default Hawthorne;
