import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import MobileImage from "./../../Image/MobileImage";
import Slider from "./../../Slider";
import { Rellax } from "../../Image/Rellax";

const data = [
  {
    image: require("./../../../assets/images/portrait/Monterey_Cypress,2016.jpg"),
    column: 1,
    top: 1,
    speed: 0,
    width: 4,
    isLarge: null,
    type: PORTRAIT,
  },

  {
    image: require("./../../../assets/images/landscape/Monterey_Cypress_detail_-_2016.jpg"),
    column: 5,
    top: 2,
    width: 4,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/Monterey_Cypress_detail_2___-_2016.jpg"),
    column: 4,
    top: 4,
    width: 3,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/portrait/The_Forest_at_4am_-_2017.jpg"),
    column: 9,
    top: 4,
    speed: 0,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter(
  (img, i) => img.type !== PORTRAIT && i !== 0 && img
);

const MontereyCypress = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper height={1.3}>
      <MobileSection width={6} left={2}>
        <Slider delay={3900} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} left={5}>
        <MobileImage src={data[0].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={4} left={0} mt={-4}>
        <Rellax speed={1} center>
          <MobileImage src={data[3].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default MontereyCypress;
