import React from "react";
import { Swiper } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import MobileImage from "./../Image/MobileImage";

SwiperCore.use([Mousewheel, Navigation, Autoplay]);

const Slider = ({ children, data, delay, setModal, initialSlide = 0 }) => (
  <Swiper
    initialSlide={initialSlide}
    loop
    navigation
    onClick={(e) =>
      setModal && setModal({ type: "swiper", data, index: e.realIndex })
    }
    slidesPerView={1}
    spaceBetween={20}
    updateOnWindowResize
    autoplay={{
      delay: delay ? delay : 3700,
      disableOnInteraction: true,
    }}
  >
    {/* For passing data */}
    {data &&
      data.map(({ image }, i) => (
        <SwiperSlide key={i}>
          <MobileImage src={image.default} />
        </SwiperSlide>
      ))}
    {/* For passing children (used by ModalMobile) */}
    {children && children}
  </Swiper>
);

export default Slider;
