import Section from "./../Section";
import { PORTRAIT } from "./../../../utils/constants";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import MobileImage from "./../../Image/MobileImage";
import Slider from "./../../Slider";

const data = [
  {
    image: require("./../../../assets/images/portrait/Memory_Phantom_5.jpg"),
    column: 1,
    top: -1,
    width: 3,
    speed: 3,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/Jeffrey_Pine_detail_-_2016.jpg"),
    column: 7,
    top: -1,
    width: 5,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/Memory_Phantom_2_-_1994.jpg"),
    column: 3,
    top: 2,
    width: 6,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Memory_Phantom_3_-_1994.jpg"),
    column: 7,
    top: 4,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/Memory_Phantom_4_-_1994.jpg"),
    column: 0,
    top: 4,
    width: 3,
    speed: 7,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type === PORTRAIT && img);

const MemoryPhantom = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper height={1.2}>
      <MobileSection width={5} left={0}>
        <MobileImage src={data[2].image.default} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={4} mt={-2} left={4}>
        <Slider delay={4200} data={swiperData} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={3} left={0} mt={-3}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default MemoryPhantom;
