import Section from "./../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/Transformer0.jpg"),
    column: 8,
    top: 0,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/Transformer1.jpg"),
    column: 0,
    top: 2,
    width: 7,
    speed: -1,
    isLarge: null,
  },
];

const Transformer = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={7} left={1}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-1} left={0}>
        <Rellax speed={0.5} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Transformer;
