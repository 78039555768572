import Section from "../Section";
import { PORTRAIT } from "./../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
const data = [
  {
    image: require("./../../../assets/images/landscape/Onenta1.jpg"),
    column: 0,
    top: 0,
    speed: 4,
    width: 12,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Onenta_3.jpg"),
    column: 3,
    top: 3,
    width: 4,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/Onenta_2.jpg"),
    column: 8,
    top: 4,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Onenta_4.jpg"),
    column: 1,
    top: 4,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Forest_at_4AM_-_2017.jpg"),
    column: 7,
    top: 6,
    width: 2,
    speed: 4,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter(
  (img, i) => img.type !== PORTRAIT && i !== 0 && img
);

const Onenta = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={8} left={0} pointer="all">
        <MobileImage src={data[0].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={4} left={0}>
        <Rellax speed={1} center>
          <Slider delay={3100} data={swiperData} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Onenta;
