import Section from "./../Section";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import { PORTRAIT } from "./../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";

const data = [
  {
    image: require("./../../../assets/images/landscape/Crosscut_-_1988.jpg"),
    column: 4,
    top: 0,
    width: 8,
    isLarge: null,
    speed: -2,
  },
  {
    image: require("./../../../assets/images/portrait/Cross-cut_-_1988.jpg"),
    column: 2,
    top: 2,
    width: 4,
    isLarge: null,
    speed: 7,
    type: PORTRAIT,
  },
];

const Crosscut = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={600} />
  ) : (
    <MobileSectionWrapper {...props}>
      <MobileSection width={6} left={2}>
        <MobileImage src={data[0].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={3} left={0} mt={-2}>
        <Rellax speed={1} center>
          <MobileImage src={data[1].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Crosscut;
