import { PORTRAIT } from "./../../../utils/constants";
import Section from "../Section";
import MobileImage from "./../../Image/MobileImage";

import Slider from "./../../Slider";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/Compromiso_Viriditas_-_1992.jpg"),
    column: 5,
    top: 0,
    width: 7,
    isLarge: null,
    speed: 0.5,
  },
  {
    image: require("./../../../assets/images/landscape/Compromiso_Viriditas_2-_1992.jpg"),
    column: 2,
    top: 2,
    width: 4,
    isLarge: null,
    speed: 4,
  },
  {
    image: require("./../../../assets/images/portrait/Eastern_White_Pine,2016.jpg"),
    column: 9,
    top: 5,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
    speed: 4,
  },
];
const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const CompromisoViriditas = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={6} left={1}>
        <Slider data={swiperData} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={2} left={0} mt={-1}>
        <MobileImage src={data[2].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default CompromisoViriditas;
