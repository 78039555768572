import Section from "./../Section";
import Slider from "./../../Slider";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/weallfeltthat_1024_2.jpg"),
    column: 0,
    top: 0,
    width: 7,
    isLarge: null,
    speed: 0.5,
  },
  {
    image: require("./../../../assets/images/landscape/weallfeltthat_1024_1.jpg"),
    column: 9,
    top: 2,
    width: 3,
    isLarge: null,
    speed: 7,
  },
];

const WeAllFeltThat = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={600} />
  ) : (
    <MobileSectionWrapper height={0.8}>
      <MobileSection width={6} left={1} mt={0.5}>
        <Slider delay={3500} data={data} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );
export default WeAllFeltThat;
