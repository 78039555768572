import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/Jeffrey_Pine,2016.jpg"),
    column: 0,
    top: 0,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/Reitzenstein_Pano_RBC_Exec_-_flat.jpg"),
    column: 2,
    top: 2,
    width: 9,
    isLarge: null,
  },
];

const RandomSection3 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={600} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={7} left={0}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={3} left={5} mt={-0.5}>
        <Rellax speed={1.5} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default RandomSection3;
