import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/unnamed_401.jpg"),
    column: 9,
    top: 0,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/stroke_snow_trees.jpg"),
    column: 0,
    top: 2,
    width: 8,
    isLarge: null,
  },
];

const RandomSection = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={500} />
  ) : (
    <MobileSectionWrapper height={0.8}>
      <MobileSection width={8} left={0} mt={1}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={2} left={1}>
        <MobileImage src={data[0].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default RandomSection;
