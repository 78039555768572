import styled from "styled-components";

const facebook = require("./../../assets/images/facebook.png");
const instagram = require("./../../assets/images/instagram.png");
const image = require("./../../assets/images/landscape/RR_profile.jpg");

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FacebookIcon = styled.a`
  width: ${({ isMobile }) => (isMobile ? "40px" : "60px")};
  height: ${({ isMobile }) => (isMobile ? "40px" : "60px")};
  background-color: white;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 3px solid transparent;
  margin: 4px;

  :hover {
    border: 3px solid lightgrey;
  }
`;

const InstagramIcon = styled(FacebookIcon)``;

const Logo = styled.img`
  width: ${({ isMobile }) => (isMobile ? "30px" : "40px")};
  height: ${({ isMobile }) => (isMobile ? "30px" : "40px")};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
  margin-top: ${({ isMobile }) => (isMobile ? "-40px" : "-220px")};
  position: absolute;
  right: ${({ isMobile }) => (isMobile ? "40px" : "-10px")};
`;

const FooterImage = ({ isMobile }) => {
  return (
    <div>
      <Container>
        <div className="wrapper" style={{ marginTop: 0 }}>
          <img
            src={image.default}
            style={{ width: "100%", zIndex: 0 }}
            alt=""
          />

          <IconContainer isMobile={isMobile}>
            <FacebookIcon
              href="https://www.facebook.com/reinhard.reitzenstein"
              target="_blank"
              isMobile={isMobile}
            >
              <Logo src={facebook.default} alt="facebook" isMobile={isMobile} />
            </FacebookIcon>
            <InstagramIcon
              href="https://www.instagram.com/allegoricalminimalism"
              target="_blank"
              isMobile={isMobile}
            >
              <Logo
                src={instagram.default}
                alt="instagram"
                isMobile={isMobile}
              />
            </InstagramIcon>
          </IconContainer>
        </div>
      </Container>
    </div>
  );
};

export default FooterImage;
