import { PORTRAIT } from "./constants";

export const handleClick = (i, el, type, images, setImages) => {
  const arr = images;
  arr[i].isLarge = !arr[i].isLarge;

  const rect = el.target.getBoundingClientRect();
  const centerY = rect.top + rect.height * 0.5;
  arr[i].offsetY = (centerY * 100) / window.innerHeight + "%";

  if (type === PORTRAIT) {
    const targetHeight = window.innerHeight - 80;
    arr[i].scale = targetHeight / rect.height;
  }

  setImages([...arr]);
};

// export const handleClick = (i, el, type, images, setImages) => {
//   const arr = images;
//   arr[i].isLarge = !arr[i].isLarge;

//   const rect = el.target.getBoundingClientRect();
//   const centerImage = rect.top + rect.height * 0.5;
//   const centerScreen = window.innerHeight * 0.5;
//   const distanceY = centerImage - centerScreen;
//   const pointOfOrigin = distanceY * 2;
//   //   const distanceOriginToCenterImage = centerImage - pointOfOrigin;
//   //   console.log(distanceY, pointOfOrigin);
//   console.log(pointOfOrigin);
//   const distanceAsPercentageOfHeight = (centerImage * 100) / distanceY;
//   //   console.log(distanceAsPercentageOfHeight + "%");
//   arr[i].offsetY = (centerImage * 100) / window.innerHeight + "%";

//   if (type === PORTRAIT) {
//     const targetHeight = window.innerHeight - 80;
//     arr[i].scale = targetHeight / rect.height;
//   }

//   setImages([...arr]);
// };
