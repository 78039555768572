import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/unnamed_204.jpg"),
    column: 8,
    top: 1,
    width: 3,
    speed: -2,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_203.jpg"),
    column: 0,
    top: 3,
    width: 6,
    speed: -1,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/unnamed_205.jpg"),
    column: 10,
    top: 4,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/unnamed_206.jpg"),
    column: 3,
    top: 6,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/unnamed_801.jpg"),
    column: 6,
    top: 3,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);
const swiperDataPortrait = data.filter(
  (img, i) => img.type === PORTRAIT && i !== 4 && img
);

const Unnamed_1 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={900} />
  ) : (
    <MobileSectionWrapper height={1.4}>
      <MobileSection width={7} left={1}>
        <Slider delay={4500} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-2} left={0}>
        <Slider
          delay={4500}
          data={swiperDataPortrait}
          setModal={props.setModal}
        />
      </MobileSection>

      <MobileSection width={3} mt={-2} left={5}>
        <MobileImage src={data[4].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Unnamed_1;
