import Section from "./../Section";
import { data } from "./FeelTheBuzz.data";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const swiperData = data.filter((img, i) => i !== 0 && img);

const FeelTheBuzz = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={1100} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={7} left={0}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={4} mt={-2} left={4} pointer="none">
        <Rellax speed={1} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default FeelTheBuzz;
