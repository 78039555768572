import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/unnamed_1003.jpg"),
    column: 1,
    top: 0,
    width: 4,
    isLarge: null,
    type: PORTRAIT,
  },

  {
    image: require("./../../../assets/images/landscape/unnamed_1001.jpg"),
    column: 5,
    top: 2,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_1002.jpg"),
    column: 8,
    top: 1,
    width: 3,
    isLarge: null,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const Unnamed_10 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={7} left={1}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-1.5} left={0} pointer="none">
        <Rellax speed={-0.5} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Unnamed_10;
