import React from "react";

import Image from "../../Image";
import Wrapper from "../../Image/Wrapper";

import { PORTRAIT } from "../../../utils/constants";

const image = require("./../../../assets/images/portrait/DSC_4108_highres.png");

const HanddrawnTree = (props) =>
  props.isMobile === false ? (
    <Wrapper height={100}>
      <Image
        noBorder
        column={-4}
        src={image.default}
        top={0}
        type={PORTRAIT}
        width={13}
      />
    </Wrapper>
  ) : (
    <></>
  );

export default HanddrawnTree;
