import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/Utah_Juniper,2016.jpg"),
    column: 1,
    top: 0,
    width: 4,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/Utah_Juniper,2016,detail.jpg"),
    column: 6,
    top: 3,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_701.jpg"),
    column: 4,
    top: 3,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/unnamed_1101.jpg"),
    column: 8,
    top: 0,
    width: 2,
    speed: 0,
    isLarge: null,
    type: PORTRAIT,
  },
];

const UtahJuniper = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={6} left={2}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={4} mt={-1} left={0}>
        <MobileImage src={data[2].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={3} left={5} mt={-2}>
        <Rellax speed={1} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default UtahJuniper;
