import React, { useRef } from "react";
import styled from "styled-components";
import Image from "./../Image";
import Wrapper from "./../Image/Wrapper";
import { Rellax } from "./../Image/Rellax";
import { PORTRAIT } from "./../../utils/constants";
import { Wrapper as ImageWrapper } from "./../Image/Image";

import MobileImage from "./../Image/MobileImage";
import MobileSectionWrapper, {
  MobileSection,
} from "./../Sections/MobileSectionWrapper";

const image = require("./../../assets/images/portrait/tree_midres.png");

const ImageComp = styled.img`
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
  transform: "scale(1.5) rotate(0deg)";
`;

const data = [
  { column: 0, top: 2, width: 2.4 },
  { column: 5, top: 1, width: 1.5 },
  { column: 8, top: 0, width: 2.7 },
  { column: 10, top: 3, width: 1.7 },
];

const FooterTrees = (props) => {
  const ref = useRef(null);

  return props.isMobile === false ? (
    <Wrapper height={600} ref={ref} zIndex={2}>
      {ref.current &&
        ref?.current?.getBoundingClientRect().y > -1000 &&
        ref?.current?.getBoundingClientRect().y < 2000 && (
          <>
            {data.map(({ column, top, isLarge, speed, width }, i) => (
              <Rellax
                isLarge={isLarge}
                speed={speed ? speed : Math.random() * 7 - 1}
                center
                key={i}
              >
                <Image
                  column={column}
                  isLarge={isLarge}
                  noBorder
                  src={image.default}
                  top={top}
                  type={PORTRAIT}
                  width={width}
                />
              </Rellax>
            ))}

            <ImageWrapper column={3} type={PORTRAIT} width={1.4} top={0}>
              <ImageComp src={image.default} />
            </ImageWrapper>
          </>
        )}
    </Wrapper>
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={4} left={0}>
        <Rellax speed={-1} center>
          <MobileImage src={image.default} />
        </Rellax>
      </MobileSection>
      <MobileSection width={4} left={4} mt={-8}>
        <MobileImage src={image.default} />
      </MobileSection>
    </MobileSectionWrapper>
  );
};

export default FooterTrees;
