import styled from "styled-components";
import { Rellax } from "./../Image/Rellax";
import MobileImage from "./../Image/MobileImage";
import MobileSectionWrapper, {
  MobileSection,
} from "./../Sections/MobileSectionWrapper";

const image = require("../../assets/images/landscape/logo.png");

const Container = styled.div`
  text-align: center;
  width: 100%;
  height: 70vh;
`;

const Image = styled.img`
  width: 322px;
  margin-top: 25vh;
`;

const Text = styled.p`
  font-size: 16px;
  letter-spacing: 3px;
  font-family: "courier";
  margin-left: 6px;
`;

const MobileText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 3px;
  font-family: "courier";
`;

const MobileContent = styled.div`
  padding-top: 120px;
  margin-bottom: 100px;
`;

const mainTitle = "the allegorical minimalist";

const CoverImage = (props) =>
  props.isMobile === false ? (
    <Container>
      <Rellax speed={-3}>
        <Image src={image.default} alt="logo" />
      </Rellax>
      <Rellax speed={-4}>
        <Text>{mainTitle}</Text>
      </Rellax>
    </Container>
  ) : (
    <MobileContent>
      <MobileSectionWrapper>
        <MobileSection width={5} left={1.5}>
          <MobileImage src={image.default} />
          <MobileText>{mainTitle}</MobileText>
        </MobileSection>
      </MobileSectionWrapper>
    </MobileContent>
  );
export default CoverImage;
