import { useState, useEffect, useRef } from "react";
import { ContainerTop, ContainerBottom, Copy } from "./TextBanner.styles";

const description =
  "allegorical minimalism - paradoxical synthesis of the material and natural world, challenging the perception of the spectator (as a critic), while denying not only a fixed interpretation, but the very possibilty of interpretation itself.";

const TextBanners = () => {
  const [scrollY, setY] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const mulitplier = window.innerWidth < 800 ? 1.4 : 1;
    const lengthOfText = description.length * 21;
    const scrollHeight = document?.body?.scrollHeight;
    const scrollSpeed = (lengthOfText / scrollHeight) * 0.5 * mulitplier;

    const setScrollPos = (val) => setY(val * -scrollSpeed);

    window.addEventListener("scroll", () => setScrollPos(window.pageYOffset));

    return () => window.removeEventListener("scroll", () => setScrollPos());
  }, []);

  return (
    <>
      <ContainerTop>
        <Copy as="div" marginLeft={scrollY} ref={ref}>
          {description}
        </Copy>
      </ContainerTop>
      <ContainerBottom>
        <Copy as="div" marginLeft={scrollY}>
          {description}
        </Copy>
      </ContainerBottom>
    </>
  );
};

export default TextBanners;
