import Section from "../Section";
import MobileImage from "./../../Image/MobileImage";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/stroke_splash.jpg"),
    column: 4,
    top: 2,
    width: 8,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/stroke_solitary_isle.jpg"),
    column: 0,
    top: 1,
    width: 8,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/stroke_new_orchard.jpg"),
    column: 1,
    top: 4,
    width: 8,
    speed: 3,
    isLarge: null,
  },
];

const RandomSection = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={500} />
  ) : (
    <MobileSectionWrapper height={0.7}>
      <MobileSection width={6} left={0} mt={0.5}>
        <MobileImage src={data[0].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={6} left={2}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={6} left={1}>
        <MobileImage src={data[2].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default RandomSection;
