import React, { useEffect, useState } from "react";

import TextBanners from "./components/TextBanner/TextBanner";
import CoverImage from "./components/CoverImage/CoverImage";
import BackgroundColor from "./components/Sections/BackgroundSections/BackgroundColor";

import Bridge8th from "./components/Sections/Bridge8th";
import CompromisoViriditas from "./components/Sections/CompromisoViriditas";
import Crosscut from "./components/Sections/Crosscut";
import DisplacementInversion from "./components/Sections/DisplacementInversion";
import DisplacementViriditas from "./components/Sections/DisplacementViriditas";
import FeelTheBuzz from "./components/Sections/FeelTheBuzz";
import Fur from "./components/Sections/Fur";
import GhostWillow from "./components/Sections/GhostWillow";
import HanddrawnTree from "./components/Sections/BackgroundSections/HanddrawnTree";
import HanddrawnTreeRight from "./components/Sections/BackgroundSections/HanddrawnTreeright";
import Hawthorne from "./components/Sections/Hawthorne";
import InvertedTreeFiller from "./components/Sections/BackgroundSections";
import InvertedTreeFillerRight from "./components/Sections/BackgroundSections/InvertedTreeFillerRight";
import MemoryCell from "./components/Sections/MemoryCell";
import MemoryPhantom from "./components/Sections/MemoryPhantom";
import MemoryVessel from "./components/Sections/MemoryVessel";
import MontereyCypress from "./components/Sections/MontereyCypress";
import NoTitle from "./components/Sections/NoTitle";
import Onenta from "./components/Sections/Onenta";
import Palendrome from "./components/Sections/Palendrome";
import PutALittleOnALittle from "./components/Sections/PutALittleOnALittle";
import R2 from "./components/Sections/R2";
import RandomSection from "./components/Sections/RandomSection";
import RandomSection_2 from "./components/Sections/RandomSection_2";
import RandomSection_3 from "./components/Sections/RandomSection_3";
import RandomSection_4 from "./components/Sections/RandomSection_4";
import RandomSection_5 from "./components/Sections/RandomSection_5";
import Stroke_1 from "./components/Sections/Stroke_1";
import Transformer from "./components/Sections/Transformer";
import Unnamed_1 from "./components/Sections/Unnamed_1";
import Unnamed_2 from "./components/Sections/Unnamed_2";
import Unnamed_3 from "./components/Sections/Unnamed_3";
import Unnamed_10 from "./components/Sections/Unnamed_10";
import UtahJuniper from "./components/Sections/UtahJuniper";
import WaitingWatchtingWaiting from "./components/Sections/WaitingWatchtingWaiting";
import WeAllFeltThat from "./components/Sections/WeAllFeltThat";
import WorldTree from "./components/Sections/WorldTree";
import FooterTrees from "./components/FooterImage/FooterTrees";
import FooterImage from "./components/FooterImage";
import ModalMobile from "./components/Modal/ModalMobile";

let data = [
  { comp: Bridge8th },
  { comp: CompromisoViriditas },
  { comp: Crosscut },
  { comp: DisplacementInversion },
  { comp: DisplacementViriditas },
  { comp: FeelTheBuzz },
  { comp: Fur },
  { comp: GhostWillow },
  { comp: Hawthorne },
  { comp: MemoryCell },
  { comp: MemoryPhantom },
  { comp: MemoryVessel },
  { comp: MontereyCypress },
  { comp: NoTitle },
  { comp: Onenta },
  { comp: Palendrome },
  { comp: PutALittleOnALittle },
  { comp: R2 },
  { comp: RandomSection },
  { comp: RandomSection_2 },
  { comp: RandomSection_3 },
  { comp: RandomSection_4 },
  { comp: RandomSection_5 },
  { comp: Stroke_1 },
  { comp: Transformer },
  { comp: Unnamed_1 },
  { comp: Unnamed_2 },
  { comp: Unnamed_3 },
  { comp: Unnamed_10 },
  { comp: UtahJuniper },
  { comp: WaitingWatchtingWaiting },
  { comp: WeAllFeltThat },
  { comp: WorldTree },
];

for (let i = data.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * i);
  const temp = data[i];
  data[i] = data[j];
  data[j] = temp;
}

data.splice(5, 0, { comp: InvertedTreeFillerRight });
data.splice(10, 0, { comp: HanddrawnTree });
data.splice(16, 0, { comp: HanddrawnTreeRight });
data.splice(21, 0, { comp: InvertedTreeFiller });

function App() {
  const [scrollPosY, setScrollPosY] = useState();
  const [isMobile, setIsMobile] = useState();
  const [modal, setModal] = useState();

  const logit = () => setScrollPosY(window.scrollY);
  const handleResize = () => setIsMobile(window.innerWidth < 800);

  useEffect(() => {
    window.onbeforeunload = () => window.scrollTo(0, 0);
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", logit);

    return () => {
      window.removeEventListener("scroll", logit);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BackgroundColor scrollPosY={scrollPosY}>
      <ModalMobile setModal={setModal} modal={modal} />
      <TextBanners />
      <CoverImage isMobile={isMobile} />

      <div className="container">
        <div className="wrapper">
          {data.map(({ comp: Comp }, index) => (
            <Comp
              key={index}
              isMobile={isMobile}
              setModal={setModal}
              index={index}
            ></Comp>
          ))}
        </div>
      </div>
      <FooterTrees isMobile={isMobile} />
      <FooterImage isMobile={isMobile} />
    </BackgroundColor>
  );
}

export default App;
