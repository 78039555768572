import Section from "./../Section";
import { PORTRAIT } from "./../../../utils/constants";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import MobileImage from "./../../Image/MobileImage";
import Slider from "./../../Slider";
import { Rellax } from "../../Image/Rellax";

const data = [
  {
    image: require("./../../../assets/images/landscape/Night_Flood_-_2019.jpg"),
    column: 6,
    top: 5,
    width: 6,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Memory_Vestle_2_-_1994.jpg"),
    column: 0,
    top: 3,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/Memory_Vestle_-_1994.jpg"),
    column: 2,
    top: 0,
    width: 6,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Memory_Vestle_detail_-_1994.jpg"),
    column: 7,
    top: 2,
    width: 4,
    isLarge: null,
  },
];

const swiperData = data.filter(
  (img, i) => img.type !== PORTRAIT && i !== 0 && img
);

const MemoryVessel = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={1.4}>
      <MobileSection width={6} left={2}>
        <Slider delay={3900} data={swiperData} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={3} left={0} mt={-2}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={6} left={1}>
        <Rellax speed={0.5} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default MemoryVessel;
