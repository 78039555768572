import React, { useState } from "react";

import Image from "./../Image";
import Modal from "./../Modal";
import Wrapper from "./../Image/Wrapper";
import { Rellax } from "./../Image/Rellax";
import { handleClick } from "./../../utils";
import { Waypoint } from "react-waypoint";

const Section = ({ data, wrapperHeight }) => {
  const [images, setImages] = useState(data);
  const [showContent, setShowContent] = useState(false);

  const calcSpeed = (speed) =>
    speed ? speed : parseInt(Math.random() * 7 - 1);

  return (
    <Waypoint
      onEnter={() => setShowContent(true)}
      onLeave={() => setShowContent(false)}
      topOffset="-100%"
      bottomOffset="-30%"
    >
      <Wrapper height={wrapperHeight}>
        {showContent && (
          <>
            <Modal images={images} setImages={setImages} />
            {images.map(
              (
                {
                  image,
                  column,
                  width,
                  top,
                  isLarge,
                  offsetY,

                  noBorder,
                  type,
                  scale,
                  speed,
                },
                i
              ) => (
                <Rellax
                  isLarge={isLarge}
                  speed={calcSpeed(speed)}
                  center
                  key={i}
                  mobile={0}
                >
                  <Image
                    column={column}
                    isLarge={isLarge}
                    onClick={(el) =>
                      handleClick(i, el, type, images, setImages)
                    }
                    scale={scale}
                    offsetY={offsetY}
                    src={image.default}
                    noBorder={noBorder}
                    top={top}
                    type={type}
                    width={width}
                  />
                </Rellax>
              )
            )}
          </>
        )}
      </Wrapper>
    </Waypoint>
  );
};

export default Section;
