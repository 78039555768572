import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import Slider from "./../../Slider";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/Seed_Tree_-_2013.jpg"),
    column: 1,
    top: 0,
    width: 5,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/unnamed_601.jpg"),
    column: 8,
    top: 2,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/unnamed_602.jpg"),
    column: 0,
    top: 1,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img, i) => i !== 0 && img);

const RandomSection4 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper height={1.6}>
      <MobileSection width={5} left={0}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={4} left={4} mt={-2}>
        <Rellax speed={0.7} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default RandomSection4;
