import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/unnamed_301.jpg"),
    column: 9,
    top: 4,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_303.jpg"),
    column: 6,
    top: 2,
    width: 4,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/unnamed_304.jpg"),
    column: 0,
    top: 2,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_302.jpg"),
    column: 4,
    top: 1,
    width: 3,
    isLarge: null,
  },
];
const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const Unnamed_3 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={6} left={0}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-2} left={5} pointer="none">
        <Rellax speed={0.7} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Unnamed_3;
