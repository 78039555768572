import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  /* border: 1px solid gray; */
  z-index: 2;
  height: ${({ width, height }) => parseInt(width * height * 300) + "px"};
`;

const MobileSectionWrapper = ({ children, height }) => {
  const totalWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return (
    <Container height={height ? height : 1} width={totalWidth / 400}>
      {children}
    </Container>
  );
};
export default MobileSectionWrapper;

const Wrapper = styled.div`
  width: ${({ width }) => width && width * 12.5 + "%"};
  margin-left: ${({ left }) => left && left * 12.5 + "%"};
  margin-top: ${({ mt }) => mt && mt * 12.5 + "%"};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 5)};
  pointer-events: ${({ pointer }) => pointer && pointer};
  position: relative;
  /* border: 1px solid coral; */
`;
export const MobileSection = ({ children, left, width, mt, pointer }) => (
  <Wrapper width={width} left={left} mt={mt} pointer={pointer}>
    {children}
  </Wrapper>
);
