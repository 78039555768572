import Section from "../Section";
import Slider from "./../../Slider";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/MemoryCell0.jpg"),
    column: 0,
    top: 2,
    width: 6,
    isLarge: null,
    speed: 3,
  },
  {
    image: require("./../../../assets/images/landscape/MemoryCell1.jpg"),
    column: 6,
    top: 2,
    width: 6,
    isLarge: null,
  },
];

const MemoryCell = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper height={0.75}>
      <MobileSection width={6} left={1} mt={0.5}>
        <Rellax speed={1.2} center>
          <Slider delay={4900} data={data} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default MemoryCell;
