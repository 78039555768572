import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/unnamed_501.jpg"),
    column: 7,
    top: 1,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },

  {
    image: require("./../../../assets/images/portrait/Spin_-_2015.jpg"),
    column: 2,
    top: 0,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const RandomSection = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={500} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={5} left={2}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={3} left={0} mt={-4}>
        <Rellax speed={1.2} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default RandomSection;
