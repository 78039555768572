import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";

import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/World_Tree_2_-_1993.jpg"),
    column: 1,
    top: 1,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/World_Tree_detail_2_-_1993_.jpg"),
    column: 5,
    top: 1,
    width: 6,
    speed: 3,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/World_Tree_detail_1_-_1993.jpg"),
    column: 2,
    top: 5,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/World_Tree_3_-_1993.jpg"),
    column: 0,
    top: 0,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/World_Tree_1_-_1993.jpg"),
    column: 7,
    top: 6,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);
const swiperDataPortrait = data.filter((img) => img.type === PORTRAIT && img);

const WorldTree = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={1200} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={7} left={0}>
        <Slider delay={3700} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-1} left={1}>
        <Slider
          delay={3800}
          data={swiperDataPortrait}
          setModal={props.setModal}
        />
      </MobileSection>
    </MobileSectionWrapper>
  );
export default WorldTree;
