import React from "react";
import Wrapper from "../../Image/Wrapper";
import styled from "styled-components";
import { PORTRAIT } from "../../../utils/constants";
import { Wrapper as ImageWrapper } from "./../../Image/Image";

const ImageComp = styled.img`
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
  opacity: 0.75;
`;

const image = require("./../../../assets/images/portrait/tree_midres.png");

const InvertedTreeFillerRight = (props) => {
  return props.isMobile === false ? (
    <Wrapper height={500}>
      <ImageWrapper column={8} type={PORTRAIT} width={3} top={0}>
        <ImageComp src={image.default} />
      </ImageWrapper>
    </Wrapper>
  ) : (
    <></>
  );
};

export default InvertedTreeFillerRight;
