import React from "react";
import styled from "styled-components";
import { PORTRAIT } from "./../../utils/constants";

const setOrigin = (column, offsetY, width) => {
  const center = column + width * 0.5;
  if (center < 5) return `0% ${offsetY}`;
  if (center < 8) return `50% ${offsetY}`;
  return `100% ${offsetY}`;
};

const calcScale = (isLarge, type, width, scale) => {
  if (isLarge) {
    if (type === PORTRAIT) {
      if (window.innerWidth < 800) {
        return `scale(${scale * 0.4})`;
      } else {
        return `scale(${scale})`;
      }
    }
    if (width === 12) return "scale(1)";
    if (width === 8) return "scale(1.5)";
    else return `scale(${10 / width})`;
  }
};

export const Wrapper = styled.div`
  margin-left: ${({ column }) => column && column * 8.333 + "%"};
  margin-top: ${({ top }) => top * 100 + "px"};
  width: ${({ width }) => width * 8.3333 + "%"};
  transform: ${({ isLarge, type, width, scale }) =>
    calcScale(isLarge, type, width, scale)};

  transition: transform 0.3s;
  transform-origin: ${({ column, offsetY, width }) =>
    setOrigin(column, offsetY, width)};
  position: absolute;
  cursor: pointer;
  z-index: ${({ isLarge }) => (isLarge ? "10" : "0")};
  padding: 5px;
`;

const ImageComp = styled.img`
  width: 100%;
  box-sizing: border-box;
  z-index: ${({ isLarge }) => (isLarge ? "10" : "0")};
  border: ${() =>
    window.innerWidth < 800
      ? "1px solid transparent"
      : "2px solid transparent"};
  border: ${({ noBorder }) => noBorder && "none"};
  opacity: ${({ opacity }) => opacity && opacity};
  transition: border-color 0.2s;
  border: ${({ isLarge }) => isLarge && "1px solid #333"};

  :hover {
    border-color: #333;
  }
`;

const Padding = styled.div`
  padding: 5px;
`;

const Image = ({
  column,
  isLarge,
  offsetY,
  onClick,
  onLoad,
  opacity,
  noBorder,
  scale,
  src,
  top,
  type,
  width,
}) => {
  return (
    <Wrapper
      column={column}
      isLarge={isLarge}
      offsetY={offsetY}
      top={top}
      type={type}
      width={width}
      scale={scale}
    >
      <Padding>
        <ImageComp
          alt=""
          noBorder={noBorder}
          isLarge={isLarge}
          onClick={onClick}
          onLoad={onLoad}
          src={src}
          opacity={opacity}
        />
      </Padding>
    </Wrapper>
  );
};

export default Image;
