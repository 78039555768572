import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

const MobileImage = ({ src, setModal }) => (
  <Image
    src={src}
    alt=""
    onClick={() => setModal && setModal({ type: "image", src })}
  />
);

export default MobileImage;
