import React from "react";
import { PORTRAIT } from "./../../../utils/constants";
import "swiper/swiper-bundle.css";
import { data } from "./Bridge8th.data";
import { Rellax } from "../../Image/Rellax";
import Section from "../Section";
import MobileImage from "./../../Image/MobileImage";

import Slider from "./../../Slider";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const Bridge8th = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={1000} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={6} left={2}>
        <Slider delay={4000} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-4}>
        <Rellax speed={1.4} center>
          <MobileImage src={data[4].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>

      <MobileSection width={2} mt={-1} left={5}>
        <MobileImage src={data[0].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );

export default Bridge8th;
