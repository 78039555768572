import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";

const data = [
  {
    image: require("./../../../assets/images/portrait/lostwood_1.jpg"),
    column: 10,
    top: 0,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/stroke_pink_tree.jpg"),
    column: 0,
    top: 1,
    width: 8,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/stroke_twin_pines.jpg"),
    column: 2,
    top: 3,
    width: 8,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/stroke_old_orchard.jpg"),
    column: 1,
    top: 5,
    width: 8,
    isLarge: null,
  },
];

const Stroke_1 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={1.2}>
      <MobileSection width={4} left={0}>
        <Rellax center speed={-1}>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>

      <MobileSection width={6} left={2} mt={-3}>
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={6} left={1} mt={0.5}>
        <MobileImage src={data[2].image.default} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={6} left={0} mt={1}>
        <Rellax center speed={1}>
          <MobileImage src={data[3].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default Stroke_1;
