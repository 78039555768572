import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import Slider from "./../../Slider";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/Waiting,_Watching_Waiting_the_aritst__-_2017.jpg"),
    column: 0,
    top: 1,
    width: 3,
    speed: -2,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/Waiting_Watching_Waiting_detail_-_2017.jpg"),
    column: 5,
    top: 3,
    width: 6,
    speed: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Waiting,_Watching_Waiting_2_-_2017.jpg"),
    column: 1,
    top: 9,
    width: 3,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/Waiting_Watching_Waiting_3_-_2017.jpg"),
    column: 2,
    top: 5,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Waiting,_Watching_Waiting_Process_Shot.jpg"),
    column: 9,
    top: 7,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);
const swiperDataPortrait = data.filter((img) => img.type === PORTRAIT && img);

const WaitingWatchtingWaiting = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={1300} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={7} left={1}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-1} left={0} pointer="none">
        <Rellax speed={0.8} center>
          <Slider
            delay={3800}
            data={swiperDataPortrait}
            setModal={props.setModal}
          />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default WaitingWatchtingWaiting;
