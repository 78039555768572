import styled from "styled-components";

const Background = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "rgb(250, 250, 242)"};
  transition: 5s all;
`;

const colors = [
  [250, 250, 242],
  [225, 225, 225],
  [245, 235, 220],
  [255, 255, 255],
  [196, 157, 130],
];

const BackgroundColor = ({ children, scrollPosY }) => {
  const calcColor = (percentage) => {
    if (percentage.isNaN) return;
    const percentile = Math.round(percentage / 25);
    return colors[percentile];
  };

  const body = document.body;
  const html = document.documentElement;
  const height = Math.max(
    body.offsetHeight,
    html.clientHeight,
    html.offsetHeight
  );

  const color = scrollPosY && calcColor((scrollPosY * 100) / height);
  const rgb = color && `rgb(${color[0]} , ${color[1]} , ${color[2]} )`;

  return <Background backgroundColor={rgb}>{children}</Background>;
};

export default BackgroundColor;
