import Section from "../Section";
import { PORTRAIT } from "./../../../utils/constants";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/No_TItle_-_1988.jpg"),
    column: 7,
    top: 1,
    width: 5,
    speed: -2,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/No_Title_2_-_1988.jpg"),
    column: 2,
    top: 1,
    width: 6,
    speed: 3,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/No_Title_3_-_1988.jpg"),
    column: 6,
    top: 5,
    width: 4,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Never_Odd_Or_Even_-_2019.jpg"),
    column: 0,
    top: 4,
    width: 3,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const NoTitle = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={900} />
  ) : (
    <MobileSectionWrapper height={1.25}>
      <MobileSection width={7} left={1}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} mt={-1} left={0} pointer="none">
        <Rellax speed={0.5} center>
          <MobileImage src={data[3].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default NoTitle;
