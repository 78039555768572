import Section from "./../Section";
import Slider from "./../../Slider";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/Displacement_Viriditas_-_1992.jpg"),
    column: 1,
    top: 2,
    width: 6,
    speed: -1,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Displacement_Viriditas_2__-_1992.jpg"),
    column: 6,
    top: 2,
    width: 4,
    isLarge: null,
  },
];

const DisplacementViriditas = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={1000} />
  ) : (
    <MobileSectionWrapper height={0.75}>
      <MobileSection width={6} left={1}>
        <Slider delay={3100} data={data} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );
export default DisplacementViriditas;
