import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: ${({ height, heightMultiplier }) =>
    height && heightMultiplier ? height * heightMultiplier + "px" : "1000px"};
  position: relative;
  pointer-events: none;
  z-index: ${({ zIndex }) => zIndex && zIndex};
`;

const Wrapper = React.forwardRef((props, ref) => (
  <Container
    ref={ref}
    height={props.height}
    zIndex={props.zIndex}
    heightMultiplier={window.innerWidth < 800 ? 0.6 : 1}
  >
    {props.children}
  </Container>
));

export default Wrapper;
