import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import Slider from "./../Slider";
import { SwiperSlide } from "swiper/react";

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: ${({ hasBorder }) => hasBorder && `4px solid white`};
  border-radius: ${({ hasBorder }) => hasBorder && "4px"};
  pointer-events: all;
`;

const SwiperContainer = styled.div`
  border: 4px solid white;
  border-radius: 4px;
  overflow: hidden;
  pointer-events: all;
  background: white;
`;

const ModalMobile = ({ modal, setModal }) => (
  <ReactModal
    isOpen={modal ? true : false}
    shouldCloseOnOverlayClick
    onRequestClose={() => setModal(false)}
    ariaHideApp={false}
  >
    {modal?.type === "image" && <Image src={modal.src} alt="" hasBorder />}
    {modal?.type === "swiper" && (
      <SwiperContainer>
        <Slider delay={4000} initialSlide={modal.index}>
          {modal.data.map(({ image }, i) => (
            <SwiperSlide key={i}>
              <Image src={image.default} />
            </SwiperSlide>
          ))}
        </Slider>
      </SwiperContainer>
    )}
  </ReactModal>
);

export default ModalMobile;
