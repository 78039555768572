import Section from "./../Section";
import { PORTRAIT } from "./../../../utils/constants";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/Palendrome1.jpg"),
    column: 0,
    top: 0,
    width: 7,
    isLarge: null,
    speed: 0.5,
  },
  {
    image: require("./../../../assets/images/portrait/Palendrome0.jpg"),
    column: 9,
    top: 2,
    width: 3,
    isLarge: null,
    speed: 7,
    type: PORTRAIT,
  },
];

const Palendrome = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={600} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={4} left={0} pointer="all">
        <MobileImage src={data[1].image.default} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={5} left={3} mt={-3}>
        <Rellax speed={1} center>
          <MobileImage src={data[0].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Palendrome;
