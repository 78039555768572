import Section from "../Section";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/landscape/Put_a_Little_on_a_Little_and_There_Will_be_a_Big_Heap_-_2018.jpg"),
    column: 0,
    top: 1,
    width: 5,
    speed: -2,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Put_a_Little_on_a_Little_and_There_Will_be_a_Big_Heap_2_-_2018.jpg"),
    column: 5,
    top: 1,
    width: 6,
    speed: 3,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/Put_a_Little_on_a_Little_and_There_Will_be_a_Big_Heap_detail_-_2018.jpg"),
    column: 2,
    top: 5,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Put_a_Little_on_a_Little_and_There_Will_be_a_Big_Heap_detail_2_-_2018.jpg"),
    column: 6,
    top: 5,
    width: 4,
    isLarge: null,
  },
];

const swiperData = data.filter((img, i) => i !== 2 && img);

const PutALittleOnALittle = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper>
      <MobileSection width={7} left={1}>
        <Slider delay={3500} data={swiperData} setModal={props.setModal} />
      </MobileSection>
      <MobileSection width={4} left={0} mt={-1}>
        <MobileImage src={data[2].image.default} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );
export default PutALittleOnALittle;
