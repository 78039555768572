import Section from "../Section";
import { PORTRAIT } from "../../../utils/constants";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";

const data = [
  {
    image: require("./../../../assets/images/landscape/Fur3.jpg"),
    column: 5,
    top: 1,
    width: 6,
    speed: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Fur2.jpg"),
    column: 1,
    top: 7,
    width: 3,
    isLarge: null,
  },

  {
    image: require("./../../../assets/images/landscape/Fur1.jpg"),
    column: 2,
    top: 3,
    width: 5,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/Fur0.jpg"),
    column: 8,
    top: 5,
    width: 2,
    isLarge: null,
    type: PORTRAIT,
  },
];

const swiperData = data.filter((img) => img.type !== PORTRAIT && img);

const Fur = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={900} />
  ) : (
    <MobileSectionWrapper height={1.2}>
      <MobileSection width={3} left={0}>
        <MobileImage src={data[3].image.default} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={6} left={2} mt={-1}>
        <Slider delay={5500} data={swiperData} setModal={props.setModal} />
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Fur;
