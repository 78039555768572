export const data = [
  {
    image: require("./../../../assets/images/landscape/Feel_The_Buzz_-_2019.jpg"),
    column: 1,
    top: 0,
    width: 5,
    speed: -1,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Feel_The_Buzz_2_-_2019.jpg"),
    column: 8,
    top: 1,
    width: 4,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Feel_The_Buzz_3_-_2019.jpg"),
    column: 5,
    top: 3,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Feel_The_Buzz_Artist_under_Neon_Lights_-_2019.jpg"),
    column: 0,
    top: 5,
    width: 3,
    speed: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/Feel_The_Buzz_with_artist_-_2019.jpg"),
    column: 6,
    top: 6,
    width: 5,
    isLarge: null,
  },
];
