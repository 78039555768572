import { PORTRAIT } from "./../../../utils/constants";
import Section from "./../Section";

import Slider from "./../../Slider";
import MobileImage from "./../../Image/MobileImage";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";

const data = [
  {
    image: require("./../../../assets/images/portrait/Displacment_Inversion_2_-_2006.jpg"),
    column: 6,
    top: 2,
    width: 4,
    isLarge: null,
    speed: 0,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/Displacment_Inversion_-_2006.jpg"),
    column: 4,
    top: 3,
    width: 3,
    speed: 8,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/portrait/Sockamore_-_2017.jpg"),
    column: 0,
    top: 0,
    width: 2,
    speed: -2.5,
    isLarge: null,
    type: PORTRAIT,
  },
];
const swiperData = data.filter((img, i) => i !== 2 && img);

const DisplacementInversion = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={800} />
  ) : (
    <MobileSectionWrapper height={1.4}>
      <MobileSection width={6} left={0}>
        <Slider delay={4000} data={swiperData} setModal={props.setModal} />
      </MobileSection>

      <MobileSection width={3} left={5} mt={-3}>
        <Rellax speed={1.5} center>
          <MobileImage src={data[2].image.default} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );

export default DisplacementInversion;
