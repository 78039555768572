import { PORTRAIT } from "./../../../utils/constants";

export const data = [
  {
    image: require("./../../../assets/images/portrait/Hawthorne_-_2016.jpg"),
    column: 8,
    top: 2,
    width: 3,
    speed: 4,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/8th_Bridge_1_-_1996.jpg"),
    column: 1,
    top: 0,
    width: 5,
    speed: -1,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/8th_Bridge_2_-_1996.jpg"),
    column: 8,
    top: 6,
    width: 4,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/8th_Bridge_3_-_1996.jpg"),
    column: 5,
    top: 3,
    width: 3,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/portrait/8th_Bridge_4_-_1996.jpg"),
    column: 0,
    top: 5,
    width: 3,
    speed: 7,
    isLarge: null,
    type: PORTRAIT,
  },
  {
    image: require("./../../../assets/images/landscape/8th_Bridge_6_-_1996.jpg"),
    column: 7,
    top: 5,
    width: 3,
    speed: 8,
    isLarge: null,
  },
];
