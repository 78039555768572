import Section from "./../Section";
import { Rellax } from "../../Image/Rellax";
import MobileSectionWrapper, { MobileSection } from "./../MobileSectionWrapper";
import Slider from "./../../Slider";

const data = [
  {
    image: require("./../../../assets/images/landscape/unnamed_101.jpg"),
    column: 8,
    top: 0,
    width: 3,
    speed: -2,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_103.jpg"),
    column: 0,
    top: 2,
    width: 7,
    speed: -1,
    isLarge: null,
  },
  {
    image: require("./../../../assets/images/landscape/unnamed_102.jpg"),
    column: 7,
    top: 2,
    width: 3,
    isLarge: null,
  },
];

const Unnamed_1 = (props) =>
  props.isMobile === false ? (
    <Section data={data} {...props} wrapperHeight={700} />
  ) : (
    <MobileSectionWrapper height={0.7}>
      <MobileSection width={6} left={1} mt={0.5}>
        <Rellax speed={0.7} center>
          <Slider delay={3200} data={data} setModal={props.setModal} />
        </Rellax>
      </MobileSection>
    </MobileSectionWrapper>
  );
export default Unnamed_1;
